<template>
    <div class="" style="width: 100%">
        <p v-if="loading">loading..</p>
        <template v-else>
            <eden-page-header title="Customer" subtitle="Uploaded Receipt" />
            <eden-table-actions :title="title" :showSearch="false">
            </eden-table-actions>
            <el-table :data="paymentReceiptData" style="width: 100%">
                <el-table-column label="Receipt">
                    <template slot-scope="scope">
                        <img :src="scope.row.file_url" alt="upload_receipt" @click="expandImage(scope)"
                            class="payment_receipt">

                            <!-- <img src="../../assets/img/attachments/pdf.svg" class="payment_receipt" alt="upload_receipt"> -->

                    </template>
                </el-table-column>
                <el-table-column prop="subscription" label="Subscription" />
                <el-table-column prop="amount" label="Amount">
                    <template slot-scope="scope">
                        {{ formatPrice(scope.row.amount) }}
                    </template>
                </el-table-column>
                <el-table-column label="Date">
                    <template slot-scope="scope">
                        {{ formatDate(scope.row.created_at, "DD MMM, YYYY") }}
                    </template>
                </el-table-column>
                <el-table-column label="Actions" width="180">
                    <template slot-scope="scope">
                        <div class="is-flex" v-if="scope.row.status === 'unconfirmed'">
                            <el-button link type="primary" size="small"
                                @click.native.prevent="confirmPayment(scope)">Approve</el-button>
                            <!-- <el-button link type="danger" size="small" plain>Declined</el-button> -->
                        </div>
                        <p v-else class="text-capitalize text-primary">{{ scope.row.status  }} </p>
                    </template>
                </el-table-column>
            </el-table>
            <eden-pagination v-if="showPagination" :from="from" :to="to" :total="total" :pageSize="per_page"
                :current-page.sync="page" />
        </template>


        <CoolLightBox :index="index" @close="index = null" :items="media" :showLightBox="false"> </CoolLightBox>
    </div>
</template>

<script>
import * as actions from "@/store/action-types";
import CoolLightBox from 'vue-cool-lightbox';
import business from "@/requests/business/index";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
    data() {
        return {
            page: 1,
            index: null,
            loading: false,
            showPagination: false,
            paymentReceiptData: [],

            media: [
                {
                    src: '',
                }
            ]
        }
    },

    computed: {
        title() {
            // const total = this.total;
            // const result = this.pageData.length;
            // return `${th ? total || 0 : result} Uploads`;
            return "10 Uploads"
        },

        payment_receipts() {
            return this.$store.getters.upload_payments_receipts;
        },
        from() {
            return this.payment_receipts.pages[this.page].from;
        },
        to() {
            return this.payment_receipts.pages[this.page].to;
        },
        total() {
            return this.payment_receipts.total;
        },
        per_page() {
            return this.payment_receipts.per_page;
        },
    },

    watch: {
        page() {
            const pageFetched = !!this.payment_receipts.pages[this.page];
            if (pageFetched) {
                this.setPageData();
            } else {
                this.getUploadedReceipts();
            }
        }
    },

    mounted() {
        this.getUploadedReceipts()
    },

    components: {
        CoolLightBox
    },

    methods: {
        getUploadedReceipts() {
            this.loading = true
            let payload = { id: this.$route.params.id, page: this.page }
            this.$store.dispatch(actions.GET_UPLOAD_RECEIPTS, payload).then(() => {
                this.setPageData()
                this.loading = false
            }).catch(() => {
                this.$elm
                this.loading = false
            })
        },
        expandImage(data) {
            this.media[0].src = data.row.file_url
            this.index = 0
        },
        setPageData() {
            this.paymentReceiptData = this.payment_receipts.pages[this.page].data;
            this.showPagination = true;
        },

        deleteRow(data) {
            console.log(data);
        },

        confirmPayment(data) {
            this.confirming = true
            business.confirmReceiptPayments({
                sub_id: this.$route.params.id,
                receipt_id: data.row.id
            }).then((response) => {
                console.log(response);
                this.confirming = false
                this.$message.success("Payment confirmed successfully")
                this.getUploadedReceipts()
            }).catch((error) => {
                this.confirming = false
                const errorMessage = error.response.data;
                if (errorMessage.errors) {
                    const errorKeys = Object.keys(errorMessage.errors);
                    this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
                } else {
                    this.$message.error(errorMessage.message);
                }
            })
        }
    }


}
</script>

<style lang="scss" scoped>
.payment_receipt {
    height: 50px;
    width: 50px;
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;
}
</style>